import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SEO from '../components/seo';
import Layout from '../components/layout';
import AssociatesImg from '../components/associateImg';
import OwnersImg from '../components/OwnerImg';
import Grid from '@material-ui/core/Grid';
import ContactForm from '../components/contactForm';
import QvcCode from '../components/qvc_code';



const useStyles = makeStyles(theme => ({
  greeting: {
    textAlign: 'center',
    fontFamily: "Shadows Into Light",
    color: '#FF0000',
    textDecoration: "underline"
  },
  center: {
    textAlign: 'center'
  },
  email: {
    textAlign: 'center',
    color: '#FF0000',
    fontSize: '17px',
  },
  address: {
    fontSize: '17px',
    textAlign: 'center'
  },
  phone: {
    textAlign: 'center',
    color: '#FF0000',
    fontSize: '17px',
  },
  color: {
    color: '#FF0000',
    fontFamily: "Shadows Into Light",
  }
}));

const ContactPage = () => {
    const classes = useStyles();

  return (
    <Layout>
      <SEO title="Contact Us"/>
      <div style={{ marginTop: '30px' }}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <div style={{ marginTop: '80px' }}>
              <h1 className={classes.greeting}>Let's Connect</h1>
              <h3 style={{textAlign: 'center'}}>Locations</h3>
              <Grid container alignItems={"center"}>
                <Grid item xs={12} md={6} lg={6}>
                  <h2 className={classes.greeting}>Tashara Gray</h2>
                  <p className={classes.address}>3765 General DeGaulle Ave. New Orleans, La 70114</p>
                  <p className={classes.center}> Email: <a className={classes.email} href={"mailto:graystaxexpress@outlook.com"}>graystaxexpress@outlook.com</a></p>
                  <p className={classes.center}> Instagram: <a className={classes.email} href={"https://www.instagram.com/graystaxexpress/"}>graystaxexpress</a></p>
                  <p className={classes.center}>Contact Number: <a className={classes.phone} href="tel:+15047666855">(504) 766-6855</a></p>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <h2 className={classes.greeting}>Moni Gray</h2>
                  <p className={classes.address}>1555 Manhattan Blvd. Ste. 1 Harvey La. 70058</p>
                  <p className={classes.center}> Email: <a className={classes.email} href={"mailto:graystaxexpress@gmail.com"}>graystaxexpress@gmail.com</a></p>
                  <p className={classes.center}> Instagram: <a className={classes.email} href={"https://www.instagram.com/graystaxexpress_llc_ygray/"}>graystaxexpress_llc_ygray</a></p>
                  <p className={classes.center}>Contact Number: <a className={classes.phone} href="tel:+15042175623">(504) 217-5623</a></p>
                </Grid>
              </Grid>
            </div>
            {/*<ContactForm/>*/}
            <div style={{width: '80%', textAlign: 'center', margin: '40px auto'}}>
              <h3 className={classes.color}>Scan the qr-code, download the app, and upload your documents to get started today! </h3>
            </div>
            <div style={{width: '30%', margin: '0px auto'}}>
              <QvcCode/>
            </div>
          </Grid>
          <Grid item xs={12} lg={5}>
            <AssociatesImg/>
          </Grid>
        </Grid>
      </div>
    </Layout>
    )
}

export default ContactPage
